module.exports = [{
      plugin: require('../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_@chakra-ui+react@2.8.2_@emotion+react@11.11.4_@types+react@18._cpd2raoboqnqplm7a3c222esbi/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3_u67c3pzyytrd5xfs2qvmxgilhm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#F5F5F5","display":"standalone","icon":"src/images/icon.png","name":"Pamela Keravuori Art","short_name":"PK.Art","start_url":"/","theme_color":"#F5F5F5","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b17d7cee86a90e1f4c7856171f1c0384"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-ackee-tracker@4.1.3_gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@_pboirww6sx626dbflc2pvscjki/node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"detailed":true,"domainId":"50405dd3-b8a6-4937-a36d-b98bbe9c847c","server":"https://info.pamelakeravuori.art","ignoreLocalhost":true,"ignoreOwnVisits":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__j6eyykj4npij7djocurqo7ie4a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
