import { extendTheme } from '@chakra-ui/react';
import '@fontsource/besley/700.css';
import '@fontsource/lunasima/400.css';
import '@fontsource/lunasima/700.css';

const theme = {
  fonts: {
    body: `Lunasima, sans-serif`,
    heading: `Lunasima, sans-serif`,
  },
};

export default extendTheme(theme);
