exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contentful-text-page-slug-tsx": () => import("./../../../src/pages/{contentfulTextPage.slug}.tsx" /* webpackChunkName: "component---src-pages-contentful-text-page-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-contentful-artwork-page-slug-tsx": () => import("./../../../src/pages/portfolio/{contentfulArtworkPage.slug}.tsx" /* webpackChunkName: "component---src-pages-portfolio-contentful-artwork-page-slug-tsx" */),
  "component---src-pages-portfolio-contentful-portfolio-group-page-slug-tsx": () => import("./../../../src/pages/portfolio/{contentfulPortfolioGroupPage.slug}.tsx" /* webpackChunkName: "component---src-pages-portfolio-contentful-portfolio-group-page-slug-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */)
}

